<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="用户名" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入用户名" allow-clear/>
              </a-form-item>
            </a-col>
<!--            <a-col :md="8" :sm="24">
              <a-form-item label="密码，md5" prop="passwd">
                <a-input v-model="queryParam.passwd" placeholder="请输入密码，md5" allow-clear/>
              </a-form-item>
            </a-col>-->
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="支付密码, md5" prop="payPasswd">
                  <a-input v-model="queryParam.payPasswd" placeholder="请输入支付密码, md5" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="账户余额" prop="money">
                  <a-input v-model="queryParam.money" placeholder="请输入账户余额" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="支付宝账号" prop="aliPay">
                  <a-input v-model="queryParam.aliPay" placeholder="请输入支付宝账号" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="微信openid" prop="openid">
                  <a-input v-model="queryParam.openid" placeholder="请输入微信openid" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="真实姓名" prop="realName">
                  <a-input v-model="queryParam.realName" placeholder="请输入真实姓名" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="头像链接" prop="avatar">
                  <a-input v-model="queryParam.avatar" placeholder="请输入头像链接" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="昵称" prop="nickname">
                  <a-input v-model="queryParam.nickname" placeholder="请输入昵称" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="用户个性签名" prop="userSign">
                  <a-input v-model="queryParam.userSign" placeholder="请输入用户个性签名" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否推送 1-是 0-否" prop="isNotice">
                  <a-input v-model="queryParam.isNotice" placeholder="请输入是否推送 1-是 0-否" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="签到天数" prop="signDay">
                  <a-input v-model="queryParam.signDay" placeholder="请输入签到天数" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['user:user:add']">
          <a-icon type="plus" />新增
        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['user:user:edit']">
          <a-icon type="edit" />修改
        </a-button>
<!--        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['user:user:remove']">
          <a-icon type="delete" />删除
        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['user:user:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="img" slot-scope="text, record">
          <div v-viewer>
            <img v-if="record.avatar" width="100" height="100" :src="record.avatar">
          </div>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['user:user:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['user:user:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['user:user:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['user:user:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUser,listUser, delUser } from '@/api/user/user'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'User',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: null,
        passwd: null,
        payPasswd: null,
        money: null,
        aliPay: null,
        openid: null,
        realName: null,
        avatar: null,
        nickname: null,
        sex: null,
        birthday: null,
        userSign: null,
        isNotice: null,
        signDay: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: '主键ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '用户名',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '密码，md5',
          dataIndex: 'passwd',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '支付密码, md5',
          dataIndex: 'payPasswd',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '账户余额',
          dataIndex: 'money',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '支付宝账号',
          dataIndex: 'aliPay',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '微信openid',
          dataIndex: 'openid',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '真实姓名',
          dataIndex: 'realName',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '头像',
          dataIndex: 'avatar',
          ellipsis: true,
          scopedSlots: { customRender: 'img' },
          align: 'center',
          preview: "img"
        },
        {
          title: '昵称',
          dataIndex: 'nickname',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          ellipsis: true,
          align: 'center',
          customRender:function (text) {
            if (text === 0) {
              return "保密";
            } else if (text === 1) {
              return "男";
            } else if (text === 2) {
              return "女";
            } else {
              return "未知状态";
            }
          }
        },
        /*{
          title: '生日，格式1990-04-22',
          dataIndex: 'birthday',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '用户个性签名',
          dataIndex: 'userSign',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '是否推送 1-是 0-否',
          dataIndex: 'isNotice',
          ellipsis: true,
          align: 'center'
        },*/
        /*{
          title: '签到天数',
          dataIndex: 'signDay',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '用户状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender:function (text) {
            if (text === 0) {
              return "禁用";
            } else if (text === 1) {
              return "正常";
            } else {
              return "未知状态";
            }
          }
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: '10%'
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户信息列表 */
    getList () {
      this.loading = true
     pageUser(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        passwd: undefined,
        payPasswd: undefined,
        money: undefined,
        aliPay: undefined,
        openid: undefined,
        realName: undefined,
        avatar: undefined,
        nickname: undefined,
        sex: undefined,
        birthday: undefined,
        userSign: undefined,
        isNotice: undefined,
        signDay: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      /*if(row.id){
        this.ids.push(row.id)
      }*/
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUser({id:ids})
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user/export', {
            ...that.queryParam
          }, `用户信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
